<script setup>
import challengeCard from '../components/challengeCard.vue'
import SKchallengeCard from '../components/skeletons/SKchallengeCard.vue'
import {
    ref,
    toRefs,
    getCurrentInstance,
    computed
} from 'vue'
import {
    useRoute,
    useRouter
} from 'vue-router'
import {
    DriverStore
} from '../pinia/Driver.js'

const props = defineProps({
    data: Object,
})
const {
    data
} = toRefs(props)
const Driver = DriverStore()
const {
    proxy
} = getCurrentInstance()
const route = useRoute()
const router = useRouter()
const items = ref([])
const date_title = ref("")
const done = ref([])
const dialog = ref(false)
const playerDone = ref([])
const isPink = ref(false)
const playerDoneCount = ref([])

// Fonction pour obtenir la date actuelle dans la timezone CEST
function getCurrentDateCEST() {
    const date = new Date();
    const offset = date.getTimezoneOffset();
    const cestOffset = -120; // CEST est UTC+2
    date.setMinutes(date.getMinutes() - offset + cestOffset);
    return date;
}

// Utiliser cette fonction pour obtenir la date actuelle
var currentDate = getCurrentDateCEST();

// Modification de la fonction de calcul de la semaine
function getWeekNumber(d) {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    return weekNo;
}

// Utiliser cette nouvelle fonction pour obtenir le numéro de semaine
var weekNumber = getWeekNumber(currentDate);

const zeroPad = (num, places) => String(num).padStart(places, '0')


getDone()



function pink() {
    isPink.value = true
    document.querySelector('.v-app-bar').style.display = "none"
    document.querySelector('.grid').style.background = "rgb(255, 0, 247)"

    document.querySelectorAll('.challenge_card').forEach(e => {
        e.style.background = "white"
        e.style.color = "black"
    })

    document.querySelectorAll('.custom').forEach(e => {
        e.style.color = "white"
    })
    
}

async function getChallenges(year = route.params.year, week = route.params.week) {

    !year ? year = currentDate.getFullYear() : ''
    !week ? week = weekNumber : ''

    router.push('/challenges/' + year + '/' + zeroPad(week, 2))

    const res = await proxy.$api({
        serviceName: "challenges",
        methodName: "GetWeek",
        parameters: [year, week]
    })
    date_title.value = res.date
    items.value = res.loop
}

async function getDone(year = route.params.year, week = route.params.week) {
    // Ensure the year and week are set. If not, default to the current year and current week
    !year ? year = currentDate.getFullYear() : '';
    !week ? week = weekNumber : '';

    const res = await proxy.$api({
        serviceName: "challenges",
        methodName: "GetDone",
        parameters: [year, week] // Pass the year and week to the backend
    });

    done.value = res;
    getChallenges(year, week); // Ensure this is called with the correct year and week
}


async function claim(id) {
    const res = await proxy.$api({
        serviceName: "challenges",
        methodName: "GetClaim",
        parameters: [id]
    })
    getDone()
}

async function PlayerDone(id) {
    const res = await proxy.$api({
        serviceName: "challenges",
        methodName: "GetPlayerDoneMoonset",
        parameters: [id]
    });
    // Assuming the response is structured with a 'names' key for players and a 'count' key for the total count
    playerDone.value = res.names; // Store only the names part for iteration
    playerDoneCount.value = res.count
    // Optionally store the count if you need to display it separately
    dialog.value = true;
}

function dateChange(e) {
    items.value = [];
    var value = e.target.value.split("-");
    var y = value[0];
    var w = value[1].substring(1);
    // Make sure to call getDone with the new year and week
    getDone(y, w);
}
</script>

<template>
<v-app-bar :elevation="2">
    <v-icon icon="mdi-trophy-award"></v-icon>
    <v-app-bar-title>Challenges</v-app-bar-title>
    <v-spacer></v-spacer>
    <template v-if="Driver.user.isAdmin == '1'">
        <!--<v-btn variant="tonal" prepend-icon="mdi-plus-box" :disabled="true">
            Create
        </v-btn>-->

        <v-btn variant="tonal" prepend-icon="mdi-link-variant" @click="pink">
            Screenshot mode
        </v-btn>
    </template>
    <template v-slot:extension>
        <div>
        <label>Selected week: </label>
        <input type="week" id="week" name="week" :value="route.params.year + '-W' + route.params.week" :max="currentDate.getFullYear() + '-W0' + weekNumber" style="color:white" @change="dateChange">
        </div>
    </template>
</v-app-bar>
    <transition name="fade" mode="out-in">
        <div key=1 class="grid skeleton" v-if="items.length == 0">
            <SKchallengeCard v-for="i in 8"/>
        </div>
        <div key=2 class="grid" v-else>
            <challengeCard v-for="(item, index) in items" :key="item.ID" :data="item" :pink="isPink" :done="done[index]" @claim="claim" @PlayerDone="PlayerDone" />
        </div>
    </transition>
    <!--<div class="card-container">
        <v-card class="my-card">
  <button class="title-button"><v-icon icon="mdi-lightning-bolt" style="margin-top:-3px;font-size: 18px;margin-left: -20px;margin-right: 12px;"></v-icon>WEEKLY CHALLENGE STREAK</button>
  <button v-for="(item, index) in done" :key="index"
          :class="['my-button', item.done == 1 ? 'button-done' : 'button-not-done']">
    #{{ item.id }}
  </button>
  <button class="claim-button"
          :style="{ background: allDone ? '#00C853' : '#E53935', pointerEvents: allDone ? 'auto' : 'none' }">
    <v-icon style="padding-right: 16px;margin-top:-2px">
      {{ allDone ? 'mdi-alert-circle-outline' : 'mdi-close-circle-outline' }}
    </v-icon>
    {{ allDone ? 'CLAIM REWARD' : 'NOT COMPLETED' }}
  </button>
  {{ allDone }}
</v-card>
</div>-->

<template>
    <div class="text-center">
        <v-dialog v-model="dialog" width="300" height="500">
            <v-card>
                <v-card-text>
                <v-card style="background: #11141c;padding-left: 6px;"><h3>{{ playerDoneCount }} <span style="opacity:60%">PARTICIPANTS</span></h3></v-card>
                    <v-list lines="one">
                        <v-list-item v-for="item in playerDone" :key="item.name" :title="item.name" :href="`https://nightriderz.world/driver/${item.name}`" style="border-radius: 4px;background: #11141c;margin-bottom: 4px;"></v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-btn variant="tonal" block @click="dialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
</template>

<style scoped>
.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 1350px;
    margin: 0 auto;
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto; /* Use 100% of the viewport height */
  width: auto; /* Use 100% of the viewport width */
  top: 0;
  left: 0;
}

.my-card {
  background: #11141c;
  margin-top: 20px;
  margin-bottom: 20px;
  height: fit-content;
  width: fit-content;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 6px; /* Adjusted from 'ma-2' to standard CSS */
}

.my-button {
  margin-right: 4px;
  margin-left: 4px; /* Example margin, adjust as needed */
  border-radius: 4px;
  pointer-events: none;
  font-weight: 600;
  padding-top: 6px;
}

.claim-button {
  margin-right: 4px;
  margin-left: 4px; /* Example margin, adjust as needed */
  border-radius: 4px;
  font-weight: 600;
  width: 250px;
  padding-top: 6px;
}

.title-button {
  margin-right: 4px;
  margin-left: 4px; /* Example margin, adjust as needed */
  border-radius: 4px;
  background: #1f2430;
  pointer-events: none;
  font-weight: 600;
  padding-top: 6px;
  width: 320px;
}

.button-done {
  background: #00C853;
}

.button-not-done {
  background: #E53935;
}

input {
    border: none;
    box-sizing: border-box;
    outline: 0;
    position: relative;
    width: 200px;
}

input[type="week"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}


</style>